

import { Vue, Component, Prop } from 'vue-property-decorator'
import { DeviceDetail } from '../../types/devices'
import { Page } from '../../types/common'

@Component
export default class SupplierWarehouse extends Vue {
  @Prop() readonly id!: string;

  page = 1
  size = 10
  total = 0
  loading = false
  tableData: DeviceDetail[] = []

  created () {
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios.get<Page<DeviceDetail>>(this.$apis.devices.selectDeviceByList, {
      supplierId: this.id,
      page: this.page,
      size: this.size
    }).then(res => {
      this.tableData = res.list
      this.total = res.total
    }).finally(() => {
      this.loading = false
    })
  }
}
